<template>
    <div class="liked">
        <memod-list :list-endpoint="listEndpoint" :no-memos-found-message="noMemosFoundMessage" />
    </div>
</template>

<script>
export default {
    components: {
        MemodList: () => import(/* webpackChunkName: "memod-list" */ "@/components/organisms/memod-list.vue")
    },
    props: {
        listEndpoint: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            noMemosFoundMessage: {
                icon: require("@assets/img/icons/heart-empty.svg"),
                title: "No Memos liked",
                text: "Nothing here...yet! Explore your Home feed and follow Creators to see their Memos here"
            }
        }
    }
}
</script>
